
import Cleave from 'cleave.js';
import {getRandomIntInclusive} from '~/plugins/generators';

export default {
  name: 'InputMask',
  inheritAttrs: false,
  props: {
    value: {
      default: null,
      required: true,
      validator(value) {
        return value === null || typeof value === 'string' || value instanceof String || typeof value === 'number';
      },
    },
    // https://github.com/nosir/cleave.js/blob/master/doc/options.md
    options: {
      type: Object,
      default: () => ({}),
    },
    // Set this prop to false to emit masked value
    labelClass: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    raw: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    float: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    error: {
      type: [String, Boolean],
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Boolean,
      default: true,
    },
    dataTest: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    styleLight: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    inputMode: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: getRandomIntInclusive(1, 100000),
      focus: false,
      engine: null,
      isInitialized: false,
      onValueChangedFn: null,
    };
  },
  computed: {
    inputId() {
      return `input-id-${this.id}`;
    },
    active() {
      return this.focus || this.value;
    },
    handlers() {
      const keys = Object.keys(this.$listeners);
      const handlers = {};
      keys.forEach((k) => {
        handlers[k] = (e) => this.$emit(k, e);
      });
      handlers.input = this.input;
      return handlers;
    },
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: this.input,
      });
    },
    inputAutocomplete() {
      return this.autocomplete ? this.autocomplete : (this.type === 'password' ? 'off' : 'on');
    },
  },
  watch: {
    /**
     * Watch for any changes in options and redraw
     *
     * @param newOptions Object
     */
    options: {
      deep: true,
      handler(newOptions) {
        if (this.engine) this.engine.destroy();
        this.engine = new Cleave(this.$refs.input, this.getOptions(newOptions));
        this.engine.setRawValue(this.value);
      },
    },
    /**
     * Watch for changes from parent component and notify cleave instance
     *
     * @param newValue
     */
    value(newValue) {
      /* istanbul ignore if */
      if (!this.engine) return;

      // when v-model is not masked (raw)
      if (this.raw && newValue === this.engine.getRawValue()) return;
      //  when v-model is masked (NOT raw)
      if (!this.raw && newValue === this.$refs.input.value) return;

      // Lastly set newValue
      this.engine.setRawValue(newValue);
    },
  },
  mounted() {
    /* istanbul ignore if */
    if (this.engine) return;
    this.engine = new Cleave(this.$refs.input, this.getOptions(this.options));
    this.engine.setRawValue(this.value);
    this.isInitialized = true;
  },
  /**
   * Free up memory
   */
  beforeDestroy() {
    /* istanbul ignore if */
    if (!this.engine) return;

    this.engine.destroy();
    this.engine = null;
    this.onValueChangedFn = null;
  },
  methods: {
    getOptions(options) {
      // Preserve original callback
      this.onValueChangedFn = options.onValueChanged;

      return Object.assign({}, options, {
        onValueChanged: this.onValueChanged,
      });
    },
    /**
     * Watch for value changed by cleave and notify parent component
     *
     * @param event
     */
    onValueChanged(event) {
      if (!this.isInitialized) return;
      const value = this.raw ? event.target.rawValue : event.target.value;
      this.$emit('input', value);

      // Call original callback method
      // if (typeof this.onValueChangedFn === 'function') {
      //   this.onValueChangedFn.call(this, event);
      // }
    },
    input(e) {
      this.$emit('input', e.target.value);
    },
  },
};
